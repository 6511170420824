import { useEffect, useState } from 'react';
import { Button, Image, Text, useMantineTheme, Stack, Flex, rem } from '@mantine/core';
import classes from '@/style/AffiliateCards.module.css';
import { AFFILIATE_CARDS } from '@/utils/constants';

interface AffiliateCard {
  title: string;
  description: string;
  imageUrl: string;
  ctaText: string;
  ctaUrl: string;
}

const AffiliateCards = () => {
  const theme = useMantineTheme();
  const [selectedCard, setSelectedCard] = useState<AffiliateCard | null>(null);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * AFFILIATE_CARDS.length);
    setSelectedCard(AFFILIATE_CARDS[randomIndex]);
  }, []);

  if (!selectedCard) return null;

  return (
    <Stack justify="center" align="center" mt={rem(36)}>
      <Flex
        align="center"
        justify="center"
        p="xl"
        gap="xl"
        direction={{ base: 'column', md: 'row' }}
        bg={theme.other.colors.deepDark}
        className={classes['affiliate-card']}
      >
        <Stack w={{ base: '100%', md: '50%' }}>
          <Text fw={700} c="white" size="lg">
            {selectedCard.title}
          </Text>
          <Text lh={1.2}>{selectedCard.description}</Text>
          <Button component="a" href={selectedCard.ctaUrl} target="_blank" rel="noopener noreferrer" w="fit-content">
            {selectedCard.ctaText}
          </Button>
        </Stack>
        <Stack w={{ base: '100%', md: '50%' }}>
          <Image src={selectedCard.imageUrl} alt={selectedCard.title} className={classes['affiliate-image']} />
        </Stack>
      </Flex>
    </Stack>
  );
};

export default AffiliateCards;
