import { Link } from '@remix-run/react';
import { Button, Modal, Stack, Text, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import type { PodcastSearchResult } from '@/types';
import classes from '@/style/ClaimPodcastSearch.module.css';
import { authPath } from '@/services/auth';

interface ImportModalProps {
  opened: boolean;
  close: () => void;
  podcast: PodcastSearchResult | undefined;
}

export default function ImportModal({ opened, close, podcast }: ImportModalProps) {
  const theme = useMantineTheme();
  if (!podcast) {
    return;
  }

  const { title, feed_url: rss_url } = podcast;
  if (!rss_url) {
    return;
  }

  const authUrl = authPath(`/dashboard/import/${encodeURIComponent(rss_url)}`);
  const onClose = () => {
    notifications.show({
      message: 'Authenticating...',
    });
    close();
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      withCloseButton={false}
      centered
      overlayProps={{
        color: '#000',
        backgroundOpacity: 0.6,
        blur: 5,
      }}
      size="xl"
      classNames={{ content: classes['claim-modal'] }}
      padding="lg"
    >
      <Stack gap="lg" m="xl">
        <Text size="lg" ta="center" lh="lg" w={'100%'}>
          We don&apos;t have{' '}
          <Text span c={theme.primaryColor} fw={600}>
            {title}
          </Text>{' '}
          in our catalog, but we&apos;ll add it if you create an account or sign in with an existing account.
        </Text>
        <Stack gap="xl" m="md" align="left">
          <Text size="sm">
            You can add the podcast by creating an account using the podcast&apos;s author or owner email address.
          </Text>
          <Button onClick={onClose} variant="filled" size="md" component={Link} to={authUrl}>
            Sign up or log in
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
}
