import { Center, Stack, rem, Title, Text, Button } from '@mantine/core';
import classes from '@/style/LandingPage.module.css';

export default function FaqCtaBlock() {
  return (
    <Center mt={rem(36)}>
      <Stack align="center" maw={1442}>
        <Title
          ta="center"
          fz={{ base: rem(32), md: rem(38) }}
          order={1}
          c="white"
          fw={700}
          lh={1.2}
          className={classes['header-title']}
        >
          Have any questions?
        </Title>
        <Text fz={{ base: rem(18), md: rem(20) }} maw={750} ta="center" lh="1.5">
          Our comprehensive FAQ section has answers to all your questions about our product. Click the button below to
          learn more.
        </Text>
        <Button component="a" href="/faq" target="_blank" rel="noopener noreferrer" w={rem(230)} size="md">
          View FAQ
        </Button>
      </Stack>
    </Center>
  );
}
