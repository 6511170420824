import { Group, Button, rem, useMantineTheme, Transition, Box } from '@mantine/core';
import { useState } from 'react';
import classes from '@/style/ClaimPodcastSearch.module.css';
import type { PodcastSearchResult } from '@/types';
import SearchInput from './SearchInput';

interface PodcastFinderProps {
  open: () => void;
  podcast: PodcastSearchResult | undefined;
  setPodcast: (podcast: PodcastSearchResult | undefined) => void;
}

export default function PodcastFinder({ open, podcast, setPodcast }: PodcastFinderProps) {
  const theme = useMantineTheme();
  const [ctaClicked, setCtaClicked] = useState(false);

  return (
    <Box h={rem(36)} w="100%">
      <Transition mounted={!ctaClicked} transition="scale-x" duration={300} timingFunction="ease-out">
        {(styles) => (
          <Button
            w={rem(300)}
            onClick={() => setCtaClicked(true)}
            color={theme.other.brandColors.sunray}
            className={classes['cta-button']}
            style={{
              ...styles,
              overflow: 'hidden',
            }}
          >
            Claim your podcast
          </Button>
        )}
      </Transition>
      <Transition mounted={ctaClicked} transition="scale-x" duration={400} timingFunction="ease-in" enterDelay={200}>
        {(styles) => (
          <Group
            justify="space-between"
            align="flex-start"
            className={classes['claim-podcast-container']}
            style={styles}
          >
            <SearchInput setPodcast={setPodcast} />
            <Button
              className={`${classes['claim-button']}`}
              size="md"
              variant="filled"
              onClick={open}
              disabled={!podcast}
              fullWidth
            >
              Let&apos;s go!
            </Button>
          </Group>
        )}
      </Transition>
    </Box>
  );
}
