import { FEATURES } from '@/utils/constants';
import FeatureSection from '@/components/FeatureSection';
import classes from '@/style/LandingPage.module.css';
import { Center, Stack } from '@mantine/core';

export default function FeaturesBlock() {
  return (
    <Center>
      <Stack className={classes['feature-section']} maw={1442}>
        {FEATURES.map((feature) => (
          <FeatureSection key={feature.id} feature={feature} />
        ))}
      </Stack>
    </Center>
  );
}
