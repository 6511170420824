import { Link } from '@remix-run/react';
import { Button, List, Modal, Stack, Text, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import type { PodcastSearchResult } from '@/types';
import classes from '@/style/ClaimPodcastSearch.module.css';
import { authPath } from '@/services/auth';

interface ClaimModalProps {
  opened: boolean;
  close: () => void;
  podcast: PodcastSearchResult | undefined;
}

export default function ClaimModal({ opened, close, podcast }: ClaimModalProps) {
  const theme = useMantineTheme();
  if (podcast === undefined) {
    return;
  }

  const { slug, title } = podcast;
  const authUrl = authPath(`/dashboard/claim/${slug}`);
  const onClose = () => {
    notifications.show({
      message: 'Authenticating...',
    });
    close();
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      withCloseButton={false}
      centered
      overlayProps={{
        color: '#000',
        backgroundOpacity: 0.6,
        blur: 5,
      }}
      size="xl"
      classNames={{ content: classes['claim-modal'] }}
      padding="lg"
    >
      <Stack gap="lg" m="xl">
        <Text size="lg" ta="center" lh="lg" w={'100%'}>
          Good news! We already have{' '}
          <Text span c={theme.primaryColor} fw={600}>
            {title}
          </Text>{' '}
          in our catalog.
        </Text>
        <Stack gap="xl" m="md" align="left">
          <Text size="sm">
            You can claim the podcast as follows:
            <List size="sm" lh="lg" m="md" type="ordered">
              <List.Item lh="lg" m="sm">
                Create an account using the podcast&apos;s author or owner email address, or
              </List.Item>
              <List.Item lh="lg" m="sm">
                Create an account using any email address, and claim the podcast with a verification code.
              </List.Item>
            </List>
          </Text>
          <Button onClick={onClose} variant="filled" size="md" component={Link} to={authUrl}>
            Sign up or log in
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
}
