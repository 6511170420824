import { Container, Blockquote, Flex, Image, Text, rem } from '@mantine/core';
import { IconQuote } from '@tabler/icons-react';
import classes from '@/style/Testimonials.module.css';

interface FeaturedTestimonial {
  name: string;
  title: string;
  quote: React.ReactNode;
  artwork: string;
  destination: string;
}

const testimonial: FeaturedTestimonial = {
  name: 'Kevin',
  title: 'Legions Podcasts admin',
  quote: (
    <Text component="span" style={{ fontWeight: 500, color: 'black' }}>
      DeepCast has a{' '}
      <Text span fw={700}>
        fantastic set of tools
      </Text>{' '}
      for podcasters and listeners alike. From Transcriptions, to Summaries, and
      <Text span fw={700}>
        {' '}
        Entities — it&apos;s a handy Subject and Topic breakdown tool!
      </Text>
    </Text>
  ),
  artwork:
    'https://deepcast.fm/_next/image?url=https%3A%2F%2Fproduction-s3-djangomediabucket-j9zlzkkigf3m.s3.amazonaws.com%2Fpodcasts%2Fimages%2F9285-a22b05d21b4c26debaaaa5e9244610e89d077c30f28e9e68d4b4c2b0bf8513d0.jpg&w=640&q=75',
  destination: 'https://deepcast.fm/podcast/the-podcast-on-haunted-hill',
};

export function FeaturedTestimonial() {
  return (
    <Container className={classes.container}>
      <Blockquote
        color="yellow"
        radius="xl"
        iconSize={60}
        icon={<IconQuote size={40} />}
        mb="xl"
        styles={{
          root: {
            backgroundColor: '#ffffff',
          },
        }}
      >
        <Flex align="center" gap="lg" direction={{ base: 'column', sm: 'row' }} wrap="nowrap">
          <Flex direction="column" gap="md">
            {testimonial.quote}
            <Text fs="italic" c="gray.7">{`– ${testimonial.name}, ${testimonial.title}`}</Text>
          </Flex>

          <Flex display={{ base: 'none', sm: 'flex' }} align="center">
            <Image src={testimonial.artwork} alt={testimonial.name} fit="contain" w={rem(100)} h={rem(100)} />
          </Flex>
        </Flex>
      </Blockquote>
    </Container>
  );
}
