import { Container, Grid, Paper, Text, rem, Title, Box, Flex, Divider } from '@mantine/core';
import { IconQuote } from '@tabler/icons-react';
import classes from '@/style/Testimonials.module.css';

interface Testimonial {
  name: string;
  title: string;
  quote: React.ReactNode;
  artwork: string;
  destination: string;
}

const testimonials: Testimonial[] = [
  {
    name: 'Madeline',
    title: 'True crime podcaster',
    quote: (
      <>
        Took some time and realized{' '}
        <Text span fw={700}>
          what a great service you provide to podcasters.
        </Text>{' '}
        I was so{' '}
        <Text span fw={700}>
          pleased to see you offering transcripts
        </Text>{' '}
        to my listeners.
      </>
    ),
    artwork:
      'https://deepcast.fm/_next/image?url=https%3A%2F%2Fproduction-s3-djangomediabucket-j9zlzkkigf3m.s3.amazonaws.com%2Fpodcasts%2Fimages%2F4599-8b595d7ec74976e3f919527b1cdbb265add6db7d410c7d2b68a71adaa30c16aa.jpg&w=640&q=75',
    destination: 'https://deepcast.fm/podcast/crimeatorium',
  },
  {
    name: 'Joseph',
    title: 'Politics and culture podcaster',
    quote: (
      <>
        Promotional social posts that took me hours to create,{' '}
        <Text span fw={700}>
          I can now create in minutes. Huge time saver!
        </Text>{' '}
        Rich posts with summaries, key takeaways, and more. ★★★★★
      </>
    ),
    artwork:
      'https://deepcast.fm/_next/image?url=https%3A%2F%2Fproduction-s3-djangomediabucket-j9zlzkkigf3m.s3.amazonaws.com%2Fpodcasts%2Fimages%2F17542-66d9356c89080246bf434a8f8ccbb3a6fc449ddee225167faa93675b83cb5865.4pwyfuqd67viyllx4dkgzpmm1dw9&w=640&q=75',
    destination: 'https://deepcast.fm/podcast/christitutionalist-tm-politics',
  },
  {
    name: 'Katherine',
    title: 'Retro nostalgia podcaster',
    quote: (
      <>
        Love your site! It&apos;s one of the best detailed sites so far on the net and{' '}
        <Text span fw={700}>
          we love what you’re doing for indie podcast creators everywhere.
        </Text>
        !
      </>
    ),
    artwork:
      'https://deepcast.fm/_next/image?url=https%3A%2F%2Fproduction-s3-djangomediabucket-j9zlzkkigf3m.s3.amazonaws.com%2Fpodcasts%2Fimages%2F3397-f8af37e5369f4353588d9254f396d216c28eeaa7bd88f0931a711d89e17c5f3a.jpg&w=640&q=75',
    destination: 'https://deepcast.fm/podcast/retro-trek-with-the-caramel-apples',
  },
  {
    name: 'Matthew',
    title: 'Kids category podcaster',
    quote: (
      <>
        Blown away with what their AI is doing in terms of{' '}
        <Text span fw={700}>
          accessibility for our shows
        </Text>
        . These tools{' '}
        <Text span fw={700}>
          add so much to the listener experience
        </Text>
        .
      </>
    ),
    artwork:
      'https://deepcast.fm/_next/image?url=https%3A%2F%2Fproduction-s3-djangomediabucket-j9zlzkkigf3m.s3.amazonaws.com%2Fpodcasts%2Fimages%2F8641-7c74d98335341e49999f29217407c53441bed02c47aa17fd17941dc2456d4f80.jpg&w=640&q=75',
    destination: 'https://deepcast.fm/podcast/the-childrens-book-podcast',
  },
];

export function Testimonials() {
  return (
    <Container>
      <Title order={2} ta="center" mb={rem(40)} className={classes['section-title']}>
        What podcasters are saying about DeepCast Pro
      </Title>
      <Grid gutter="md" justify="center">
        {testimonials.map((testimonial, index) => (
          <Grid.Col key={index} span={{ base: 12, sm: 10, md: 6, lg: 6 }}>
            <Paper shadow="md" p="xl" className={classes['testimonial-card']}>
              <Box style={{ position: 'relative', paddingLeft: rem(30) }}>
                <IconQuote size={24} className={classes['quote-icon']} />
                <Text size="md" fw={500} mb="md" ml="xs" lh="lg" c="white.3">
                  {testimonial.quote}
                </Text>
              </Box>
              <Divider mt={rem(12)} mb={rem(18)} />
              <Flex align="center" gap="lg" wrap="nowrap" className={classes.authorInfo}>
                <a href={testimonial.destination} target="_blank" rel="noopener noreferrer">
                  <img
                    src={testimonial.artwork}
                    alt={testimonial.name}
                    width={40}
                    height={40}
                    className={classes['rounded-border']}
                  />
                </a>
                <Box>
                  <Text fw={500} c="white">
                    {testimonial.name}
                  </Text>
                  <Text size="sm" className={classes['title']}>
                    {testimonial.title}
                  </Text>
                </Box>
              </Flex>
            </Paper>
          </Grid.Col>
        ))}
      </Grid>
    </Container>
  );
}
