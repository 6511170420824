import { Stack, rem, Center, Title, Text, Button } from '@mantine/core';
import { useScrollIntoView } from '@mantine/hooks';

type ScrollIntoViewFunction = ReturnType<typeof useScrollIntoView>['scrollIntoView'];

type SubheadlineBlockProps = {
  scrollIntoView: ScrollIntoViewFunction;
};

export default function SubheadlineBlock({ scrollIntoView }: SubheadlineBlockProps) {
  return (
    <Center>
      <Stack align="center" pt="xl" pb="xl" maw={rem(1200)}>
        <Title order={4} ta="center" c="white" ff="Plush">
          From first episode to full catalog. Choose your perfect starting point.
        </Title>
        <Text ta="center" c="white.3" lh={1.4} maw={rem(800)}>
          DeepCast Pro - Basic offers{' '}
          <Text span c="surf.8" fw="600">
            the most competitive pricing
          </Text>{' '}
          for speaker-labled transcriptions, AI-derived insights, and marketing collateral in the industry. Start free,
          then scale up from there!
        </Text>
        <Button variant="light" onClick={() => scrollIntoView({ alignment: 'start' })}>
          See offering details
        </Button>
      </Stack>
    </Center>
  );
}
